<template>
  <v-dialog v-model="model" :width="computedWidth" persistent :fullscreen="fullscreen" scrollable overlay-opacity="0.65" eager>
    <template #activator="{ props }">
      <slot name="activator" v-bind="{ props }" />
    </template>
    <v-card :loading="loading">
      <v-card-title class="d-flex justify-space-between">
        <div>
          <slot name="title" />
        </div>
        <div>
          <v-btn v-if="!smAndDown && allowFullscreen" :icon="fullscreenIcon" variant="plain" density="comfortable" @click="fullscreen = !fullscreen" />
          <v-btn :icon="mdiClose" variant="plain" density="comfortable" @click="model = false" />
        </div>
      </v-card-title>
      <v-divider class="mx-4 pb-2" />
      <v-card-text>
        <slot />
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-center">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { mdiArrowCollapse, mdiArrowExpand, mdiClose } from '@mdi/js'

const model = defineModel<boolean>()
const properties = withDefaults(defineProps<{
  loading?: boolean
  width?: number
  allowFullscreen?: boolean
  isFullScreen?: boolean
}>(), { allowFullscreen: true })
const { smAndDown } = useDisplay()
const fullscreen = ref(false)
const computedWidth = computed(() => fullscreen.value ? '100%' : (properties.width ? properties.width : '800'))
const fullscreenIcon = computed(() => fullscreen.value ? mdiArrowCollapse : mdiArrowExpand)

watch(model, (n, _o) => {
  if (n && (smAndDown.value || properties.isFullScreen)) {
    fullscreen.value = true
  }
})
</script>
